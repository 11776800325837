import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];

  connect() {
    this.toggleSubmitButton();
    this.addListeners();
  }

  addListeners() {
    this.formTarget.addEventListener("input", () => {
      this.toggleSubmitButton();
    });
  }

  toggleSubmitButton() {
    const submitButton = this.formTarget.querySelector("button[type='submit']");
    submitButton.disabled = !this.formTarget.checkValidity();
  }
}
