/* global Stimulus */
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submitButton", "signaturePad", "signatureInput", "form"];

  connect() {
    this.toggleSubmitButton();
    this.signaturePadElement = document.querySelector("[data-controller='signature_pad']");
    this.signaturePadElement.addEventListener(
      "signature_pad:signed",
      this.toggleSubmitButton.bind(this)
    );
    this.signaturePadElement.addEventListener(
      "signature_pad:unsigned",
      this.toggleSubmitButton.bind(this)
    );
    this.element.addEventListener("change", this.checkboxChanged.bind(this));
  }

  checkboxChanged() {
    this.toggleSignaturePad();
    this.toggleSubmitButton();
  }

  allCheckboxesChecked() {
    const checkboxes = this.element.querySelectorAll("input[type='checkbox']");
    return Array.from(checkboxes).every((checkbox) => checkbox.checked);
  }

  toggleSignaturePad() {
    if (this.allCheckboxesChecked()) {
      this.signaturePadTarget.classList.remove("hidden");
      this.getSignaturePad().show();
    } else {
      this.signaturePadTarget.classList.add("hidden");
    }
  }

  toggleSubmitButton() {
    if (this.allCheckboxesChecked() && this.isSigned()) {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }

  submitSignature() {
    this.getSignaturePad()
      .getSignatureData()
      .then(this.blobToBase64)
      .then((base64String) => {
        if (this.signatureInputTarget) {
          this.signatureInputTarget.value = base64String;
        }
        console.log("submitting form");
        this.formTarget.submit();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  isSigned() {
    return this.getSignaturePad().isSigned();
  }

  blobToBase64(blob) {
    return new Promise((resolve, _reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(blob);
    });
  }

  getSignaturePad() {
    if (this.signaturePadElement) {
      const signaturePadController = Stimulus.getControllerForElementAndIdentifier(
        this.signaturePadElement,
        "signature_pad"
      );
      if (signaturePadController) {
        return signaturePadController;
      }
    }
    throw new Error("Signature pad not found");
  }

  uploadData(url, formData) {
    return fetch(url, {
      method: "POST",
      body: formData,
    }).then((response) => {
      if (!response.ok) {
        throw new Error("Network error");
      }
      return response.json();
    });
  }
}
