import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["radio", "checkboxGroup", "pillWrapper"];

  connect() {
    this.initializeFilter();

    document.addEventListener("content_switch:changed", this.initializeFilter.bind(this));
    document.addEventListener(
      "content_filter:changed",
      this.initializePillsAndCheckboxes.bind(this)
    );
  }

  initializeFilter() {
    const urlParams = new URLSearchParams(window.location.search);
    const filterFromUrlParam = urlParams.has("category") ? "category" : "priority";
    this.updateActiveFilter(filterFromUrlParam);
  }

  initializePillsAndCheckboxes(event) {
    const { checkedRadio, checkedCheckboxes } = event.detail;

    this.updateActiveFilter(checkedRadio);
    if (this.hasPillWrapperTarget) {
      this.setCheckedValues(this.pillWrapperTargets, checkedRadio, checkedCheckboxes);
    }

    if (this.hasCheckboxGroupTarget) {
      this.setCheckedValues(this.checkboxGroupTargets, checkedRadio, checkedCheckboxes);
    }
  }

  setCheckedValues(elements, checkedRadio, checkedCheckboxes) {
    const targets = elements
      .find((element) => element.dataset.filterTarget === checkedRadio)
      .querySelectorAll("input[type=checkbox]");

    targets.forEach((target) => {
      target.checked = checkedCheckboxes.includes(target.value);
    });
  }

  updateActiveFilter(filter) {
    this.radioTargets.forEach((radio) => {
      radio.checked = radio.value === filter;
    });
    this.toggleElements(filter, this.checkboxGroupTargets);
    this.toggleElements(filter, this.pillWrapperTargets);
  }

  updateFrame(event) {
    const filter = event.currentTarget.value;
    this.toggleElements(filter, this.checkboxGroupTargets);
    this.fireFilterEvent();
  }

  toggleElements(filter, elements) {
    elements.forEach((element) => {
      element.classList.toggle("hidden", element.dataset.filterTarget !== filter);
    });
  }

  fireFilterEvent() {
    const checkedRadio = this.radioTargets.find((radio) => radio.checked).value;
    const checkedCheckboxes = this.checkboxGroupTargets
      .find((checkboxGroup) => checkboxGroup.dataset.filterTarget === checkedRadio)
      .querySelectorAll("input[type=checkbox]:checked");

    this.element.dispatchEvent(
      new CustomEvent("content_filter:changed", {
        bubbles: true,
        detail: {
          checkedRadio: checkedRadio,
          checkedCheckboxes: Array.from(checkedCheckboxes).map((checkbox) => checkbox.value),
        },
      })
    );
  }

  firePillFilterEvent(event) {
    const checkedRadio = event.currentTarget.dataset.radioValue;
    const checkedPills = this.pillWrapperTargets
      .find((pillWrapper) => pillWrapper.dataset.filterTarget === checkedRadio)
      .querySelectorAll("input[type=checkbox]:checked");

    this.element.dispatchEvent(
      new CustomEvent("content_filter:changed", {
        bubbles: true,
        detail: {
          checkedRadio: checkedRadio,
          checkedCheckboxes: Array.from(checkedPills).map((pill) => pill.value),
        },
      })
    );
  }
}
