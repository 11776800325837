import { Controller } from "@hotwired/stimulus";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export default class extends Controller {
  static targets = ["content"];

  connect() {
    this._renderContent();
    this._handleLinks();
    window.sendSegmentAnalytics("Nok", "Article", "Viewed", this.getSegmentProps());
  }

  _renderContent() {
    const content = JSON.parse(this.data.get("content"));
    this.contentTarget.innerHTML = documentToHtmlString(content);
  }

  _handleLinks() {
    const links = this.contentTarget.querySelectorAll("a");
    const linksArray = Array.prototype.slice.call(links);
    linksArray.forEach((link) => {
      if (!link.href.includes("/next_of_kin/")) {
        link.setAttribute("target", "_blank");
      }
    });
  }

  getSegmentProps() {
    const id = window.location.pathname.split("/")[3];
    var article_referrer = ""
    if (document.referrer) {
      article_referrer = new URL(document.referrer).pathname.split("/")[2];
    }
    
    return {
      title: this.data.get("title"),
      article_id: id,
      article_location: article_referrer,
      customer_type: this.data.get("customer-type"),
      service_type: this.data.get("service-type"),
    };
  }
}
